import { template as template_2fb46c34a2244e1fa7e48b6e725f6c9d } from "@ember/template-compiler";
const FKLabel = template_2fb46c34a2244e1fa7e48b6e725f6c9d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
