import { template as template_294a637467e84c61855eef5d6ce1acb2 } from "@ember/template-compiler";
const FKControlMenuContainer = template_294a637467e84c61855eef5d6ce1acb2(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
