import { template as template_b13434a31bcc4d5ba7c2f3ba5239bb10 } from "@ember/template-compiler";
import NotificationsTracking from "discourse/components/notifications-tracking";
import { i18n } from "discourse-i18n";
const CategoryNotificationsTracking = template_b13434a31bcc4d5ba7c2f3ba5239bb10(`
  <NotificationsTracking
    @onChange={{@onChange}}
    @levelId={{@levelId}}
    @showCaret={{@showCaret}}
    @showFullTitle={{@showFullTitle}}
    @prefix="category.notifications"
    @title={{i18n "category.notifications.title"}}
    class="category-notifications-tracking"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default CategoryNotificationsTracking;
