import { template as template_918527a091de4523b416784b77629959 } from "@ember/template-compiler";
const WelcomeHeader = template_918527a091de4523b416784b77629959(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
